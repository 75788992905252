import React, { useContext, useState, useEffect } from 'react';
import StoreContext from '~/layouts/StoreContext';
import { BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox, Grid, Row, Col } from '~/components/.base/containers';
import PlusIcon from '~/images/icons/plus.svg';
import MinusIcon from '~/images/icons/minus.svg';
import { WhiteButton } from '~/components/.base/buttons';
import SubscriptionIcon from '~/images/icons/pdp/subscription.svg';
import SubscriptionRemoveIcon from '~/images/icons/pdp/subscription_remove.svg';
import { getProductVariantInventory } from '~/helpers/requests/association-requests';
import { getShopifyImageTransformedSrc } from 'helpers';
import TagIcon from '~/images/icons/tag.svg';

const LineItem = ({ lineItem, index }) => {
  const {
    removeLineItem,
    removeBaseLineItem,
    updateLineItem,
    addVariantToCart,
    closeCart,
    store: { client, checkout, trueLinePrices, lobsterTitle, lobsterSubscriptionTitle },
  } = useContext(StoreContext);

  const [quantityAvailable, setQuantityAvailable] = useState(0);
  const [isUpsellItem, setIsUpsellItem] = useState(false);

  useEffect(() => {
    (async () => {
      if (lineItem.variant.id) {
        const availabilities = await getProductVariantInventory(lineItem.variant.id);
        setQuantityAvailable(availabilities.data.data.node.quantityAvailable);
      }
    })();
  }, []);

  // ******** UP SELL CHECK *********
  useEffect(() => {
    if (lineItem.customAttributes) {
      lineItem.customAttributes.forEach(item =>
        item.key === 'upsell_product' ? setIsUpsellItem(true) : null
      );
    }
  }, []);

  const variantImage = lineItem.variant.image ? (
    <img
      src={getShopifyImageTransformedSrc(lineItem.variant.image.src, '_60x60_crop_center')}
      alt=""
      height="90px"
    />
  ) : null;
  const handleRemove = () => {
    removeLineItem(client, checkout.id, lineItem.id);
  };
  const handleSubtract = () => {
    if (lineItem.quantity === 1) {
      handleRemove();
    } else {
      updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity - 1);
    }
  };
  const handleAdd = () => {
    if (quantityAvailable === lineItem.quantity + 1) {
      return;
    }
    if (isUpsellItem) {
      return;
    }
    updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity + 1);
  };
  const handleRegularProductSubstitute = async variantId => {
    await removeLineItem(client, checkout.id, lineItem.id);
    let attrs = [];
    for (let attr of lineItem.customAttributes) {
      if (attr.key !== 'regular_variant_id' && attr.key !== 'subscription_interval') {
        attrs.push({ key: attr.key, value: attr.value });
      }
    }
    // NOTE: remove this until subscriptions are enabled again
    // attrs.push({
    //   key: 'subscription_variant_id',
    //   value: lineItem.variant.id,
    // });
    addVariantToCart(variantId, lineItem.quantity, attrs);
  };
  console.log(trueLinePrices, index, trueLinePrices[index]);
  return trueLinePrices.length && trueLinePrices[index] ? (
    <Grid justify="space-between" align="center" className="line-item">
      <Row className="upper-half">
        <Col className="left-half" size={5}>
          <FlexBox>
            <div className="line-item-img">{variantImage}</div>
            <div>
              <BodyText className="line-item-title">
                {lineItem.title}
                {lineItem.variant.title !== 'Default Title' ? ` - ${lineItem.variant.title}` : ''}
              </BodyText>
              {lineItem.customAttributes.find(prop => prop.key === 'subscription_interval') ? (
                <BodyTextAlt md="0 0 10px 1rem">
                  Ships every{' '}
                  {
                    lineItem.customAttributes.find(prop => prop.key === 'subscription_interval')
                      ?.value
                  }{' '}
                  days
                </BodyTextAlt>
              ) : null}
              <button className="remove-btn" onClick={handleRemove}>
                Remove
              </button>
            </div>
          </FlexBox>
        </Col>
        <Col className="right-half" size={1}>
          {trueLinePrices[index].codeDiscountAmount ? (
            <div className="savings">
              <FlexBox justify="flex-end">
                <BodyTextAlt md="0 0 5px">
                  <span class="sr-only">Original price:</span>
                  <strike>${Number(trueLinePrices[index].basePrice).toFixed(2)}</strike>
                </BodyTextAlt>{' '}
                <BodyTextAlt md="0 0 5px">
                  <span class="sr-only">Current price:</span>$
                  {Number(trueLinePrices[index].price).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
              {Math.round(
                Number(trueLinePrices[index].basePrice) -
                  Number(trueLinePrices[index].price) -
                  Number(trueLinePrices[index].codeDiscountAmount)
              ) > 0 ? (
                <BodyTextAlt md="0 0 5px" color="#d4212c">
                  Saving $
                  {(
                    Number(trueLinePrices[index].basePrice) -
                    Number(trueLinePrices[index].price) -
                    Number(trueLinePrices[index].codeDiscountAmount)
                  ).toFixed(2)}
                </BodyTextAlt>
              ) : null}
              <FlexBox align="center">
                <TagIcon
                  style={{
                    width: '17px',
                    marginRight: '5px',
                    fill: '#555',
                  }}
                />
                <BodyTextAlt md="0" color="#d4212c">
                  {trueLinePrices[index].code}:{` `}$
                  {Number(trueLinePrices[index].codeDiscountAmount).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
            </div>
          ) : trueLinePrices[index].price !== Number(trueLinePrices[index].basePrice) ? (
            <div className="savings">
              <FlexBox>
                <BodyTextAlt md="0 0 5px">
                  <span class="sr-only">Original price:</span>
                  <strike>${Number(trueLinePrices[index].basePrice).toFixed(2)}</strike>
                </BodyTextAlt>{' '}
                <BodyTextAlt md="0 0 5px">
                  <span class="sr-only">Current price:</span>$
                  {Number(trueLinePrices[index].price).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
              <BodyTextAlt md="0" color="#d4212c">
                Saving $
                {(
                  Number(trueLinePrices[index].basePrice) - Number(trueLinePrices[index].price)
                ).toFixed(2)}
              </BodyTextAlt>
            </div>
          ) : (
            <BodyTextAlt>${trueLinePrices[index].price.toFixed(2)}</BodyTextAlt>
          )}
        </Col>
      </Row>
      <Row className="lower-half">
        {!lineItem.customAttributes.find(prop => prop.key === 'recipient_name') ? (
          <Col size={2}>
            <div className="quantity">
              <button
                className="quantity__adjust"
                onClick={() => {
                  if (
                    (lobsterTitle === lineItem.title && lineItem.quantity === 2) ||
                    (lobsterSubscriptionTitle === lineItem.title && lineItem.quantity === 2)
                  ) {
                    return;
                  } else {
                    handleSubtract();
                  }
                }}
              >
                <MinusIcon />
              </button>
              {lineItem.quantity < 10 ? `0${lineItem.quantity}` : lineItem.quantity}
              <button className="quantity__adjust" onClick={handleAdd}>
                <PlusIcon style={{ marginBottom: '1px' }} />
              </button>
            </div>
          </Col>
        ) : (
          <div style={{ height: '20px' }} />
        )}
      </Row>
    </Grid>
  ) : null;
};

export default LineItem;
