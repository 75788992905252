import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { getImage } from '../../../helpers';
import StoreContext from '~/layouts/StoreContext';
import { BodyText, BodyTextAlt } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import { FormControl } from '~/components/.base/inputs';
import LineItem from './LineItem.js';
import { CartUpsells } from '~/components/CartPage';
import './CartMobile.scss';
import CheckIcon from '~/images/icons/checkbox.js';
import TagIcon from '~/images/icons/tag.svg';
import { useMemo } from 'react';

const Payments = styled.div`
  .gatsby-image-wrapper {
    width: 180px;
    max-width: 100%;
    margin: 10px auto 0;
  }
`;

const GiftNote = styled.div`
  padding: 1rem 1rem 0.5rem;
  border-top: 1px solid #e1e4e4;
  .label {
    color: #d4212c;
  }
`;

const Cart = ({ cartImages }) => {
  const {
    store: {
      checkout,
      checkout: { discountApplications },
      trueLineItemsBasePriceSubtotalNoDiscounts,
      trueLineItemsSubtotalNoDiscounts,
      trueLineItemsSubtotalNoDiscountsNoApparel,
      trueLineItemsSubtotal,
    },
  } = useContext(StoreContext);
  const [isGift, setIsGift] = useState(false);
  const [giftNote, setGiftNote] = useState('');
  const handleCheckout = () => {
    if (window && lineItems.length) {
      window.location.href = '/checkout';
    }
  };
  const lineItems = useMemo(
    () =>
      checkout.lineItems.map((lineItem, i) => {
        return (
          <li key={lineItem.id.toString()}>
            <LineItem index={i} lineItem={lineItem} />
          </li>
        );
      }),
    [checkout.lineItems]
  );

  const $ref = useRef(null);

  const handleIsGift = e => {
    e.preventDefault();
    setIsGift(isGift => !isGift);
  };

  useEffect(() => {
    if (isGift && $ref?.current) {
      const $note = $ref.current.querySelector('#giftNoteMobile');

      $note.focus();
    }
  }, [isGift]);

  const handleGiftNoteChange = val => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('COUSINS_MAINE_GN', val);
      setGiftNote(val);
    }
  };
  return (
    <FlexBox
      ref={$ref}
      className="cart-page-mobile"
      direction="column"
      align="center"
      justify="space-between"
    >
      <div className="cart-top">
        <div className="shipping-bar">
          {trueLineItemsSubtotalNoDiscountsNoApparel &&
          trueLineItemsSubtotalNoDiscountsNoApparel >= 250 ? (
            <>
              <BodyText>You have earned free shipping!</BodyText>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: '100%',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <BodyText>
                Spend $
                {(
                  250 -
                  (trueLineItemsSubtotalNoDiscountsNoApparel
                    ? trueLineItemsSubtotalNoDiscountsNoApparel
                    : 0)
                ).toFixed(2)}{' '}
                more and get free shipping!
              </BodyText>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: `${
                      (trueLineItemsSubtotalNoDiscountsNoApparel
                        ? trueLineItemsSubtotalNoDiscountsNoApparel
                        : 0) / 2.5
                    }%`,
                  }}
                />
              </div>
            </>
          )}
        </div>
        {lineItems.length ? (
          <ul className="line-items-container">{lineItems}</ul>
        ) : (
          <div className='="line-items-container'>
            <div className="no-items">
              <p>You have no items in your cart.</p>
              <WhiteButton as={Link} to="/shop">
                Continue Shopping
              </WhiteButton>
            </div>
          </div>
        )}
        <CartUpsells />
      </div>
      <div className="cart-bottom">
        <GiftNote>
          <div>
            <FlexBox
              className="addGiftNote__label"
              as="label"
              align="flex-end"
              justify="space-between"
              onClick={handleIsGift}
            >
              Add a gift note
              {isGift ? (
                <CheckIcon className="addGiftNote__check" />
              ) : (
                <div className="addGiftNote__uncheck" />
              )}
              <input
                className="addGiftNote__input"
                type="radio"
                name="addGiftNote"
                value="true"
                checked={isGift}
              />
            </FlexBox>
            {isGift && (
              <FormControl>
                <label htmlFor="giftNoteMobile">Gift Note</label>
                <textarea
                  id="giftNoteMobile"
                  aria-label="Gift Note"
                  style={{
                    width: '100%',
                  }}
                  name="giftNote"
                  rows="4"
                  onChange={e => handleGiftNoteChange(e.target.value)}
                  value={giftNote}
                  placeholder="Add your note here"
                  aria-describedby="giftNoteInstructionMob"
                />
                <BodyText id="giftNoteInstructionMob">
                  Free and optional. Don’t worry, we won’t show the pricing details to the person
                  receiving this gift.
                </BodyText>
              </FormControl>
            )}
          </div>
        </GiftNote>
        <div className="totals-cont">
          <div className="total-item">
            <BodyTextAlt>Items:</BodyTextAlt>
            <BodyTextAlt>${trueLineItemsBasePriceSubtotalNoDiscounts.toFixed(2)}</BodyTextAlt>
          </div>
          {trueLineItemsBasePriceSubtotalNoDiscounts > trueLineItemsSubtotalNoDiscounts ? (
            <div className="total-item savings">
              <BodyTextAlt color="#d4212c">Savings:</BodyTextAlt>
              <BodyTextAlt color="#d4212c">
                $
                {(
                  trueLineItemsBasePriceSubtotalNoDiscounts - trueLineItemsSubtotalNoDiscounts
                ).toFixed(2)}
              </BodyTextAlt>
            </div>
          ) : null}
          {trueLineItemsSubtotalNoDiscounts > trueLineItemsSubtotal ? (
            <div className="total-item savings">
              <FlexBox>
                <TagIcon
                  style={{
                    width: '17px',
                    marginRight: '5px',
                    fill: '#555',
                  }}
                />
                <BodyTextAlt color="#d4212c">{discountApplications?.[0]?.code}:</BodyTextAlt>
              </FlexBox>
              <BodyTextAlt color="#d4212c">
                ${(trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal).toFixed(2)}
              </BodyTextAlt>
            </div>
          ) : null}
          <div className="total-item">
            <BodyTextAlt>Subtotal:</BodyTextAlt>
            <BodyTextAlt>${trueLineItemsSubtotal.toFixed(2)}</BodyTextAlt>
          </div>
          <BodyText align="center">Taxes and shipping calculated at checkout</BodyText>
          <Payments>
            <Image fluid={getImage(cartImages.edges, 'payment_icons')} alt="" />
          </Payments>
        </div>
        <div className="total-item checkout-btn">
          <RedButton disabled={!lineItems.length} onClick={handleCheckout}>
            Checkout
          </RedButton>
        </div>
      </div>
    </FlexBox>
  );
};

export default Cart;
