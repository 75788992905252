import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import StoreContext from '~/layouts/StoreContext';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';

// Hooks
import { useBackground } from '../../../hooks/use-background';
import { getImage } from '../../../helpers';
import { BodyText, BodyTextAlt, HeadingLarge } from '~/components/.base/headings';
import LineItem from './LineItem.js';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { BackgroundDiv, PageContainerEcomm } from '~/components/.base/containers';
import { FlexBox } from '~/components/.base/containers';
import queryString from 'query-string';
// styles
import './Cart.scss';
import TagIcon from '~/images/icons/tag.svg';
import GiftIcon from '~/images/icons/gift.svg';
import { CheckboxInput, FormControl } from 'components/.base/inputs';

const Payments = styled.div`
  .gatsby-image-wrapper {
    width: 180px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: auto;
  }
`;

const Cart = ({ cartImages }) => {
  const {
    store: {
      client,
      checkout,
      trueLineItemsSubtotal,
      trueLineItemsSubtotalNoDiscounts,
      trueLineItemsBasePriceSubtotalNoDiscounts,
    },
    updateCheckoutWithAbandonedCart,
  } = useContext(StoreContext);
  const [isGift, setIsGift] = useState(false);
  const [giftNote, setGiftNote] = useState('');
  const lineItems = checkout.lineItems.map((lineItem, i) => {
    return <LineItem key={lineItem.id.toString()} lineItem={lineItem} index={i} />;
  });

  const $ref = useRef(null);

  const enableIsGift = () => {
    setIsGift(true);
  };

  const disableIsGift = e => {
    e?.preventDefault();
    setIsGift(false);
  };

  useEffect(() => {
    if (isGift && $ref?.current) {
      const $note = $ref.current.querySelector('#giftNote');

      $note.focus();
    }
  }, [isGift]);

  const handleGiftNoteChange = val => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('COUSINS_MAINE_GN', val);
      setGiftNote(val);
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('COUSINS_MAINE_GN')) {
        setGiftNote(localStorage.getItem('COUSINS_MAINE_GN'));
      }
    }
  }, []);
  useEffect(() => {
    (async () => {
      const { id } = queryString.parse(window.location.search);
      if (typeof window !== 'undefined' && id) {
        localStorage.removeItem('shopify_checkout_id');
        localStorage.setItem('shopify_checkout_id', id);
        const data = await client.checkout.fetch(id);
        const formatted = data.lineItems.map(item => {
          return {
            variantId: item.variant.id,
            quantity: item.quantity,
          };
        });
        updateCheckoutWithAbandonedCart(formatted, id);
      } else {
        return null;
      }
    })();
  }, []);

  const bgImages = useBackground();
  return (
    <PageContainerEcomm
      ref={$ref}
      className={`cart-page ${checkout?.lineItems?.length ? '' : 'empty'}`}
    >
      <BackgroundDiv
        z="-1"
        css={css`
          left: 50%;
          top: 50%;
          max-height: 100%;
          width: 883px;
          max-width: 100%;
          overflow: hidden;
          transform: translate(-50%, -50%);
        `}
      >
        <Image fluid={getImage(bgImages.edges, 'blurred_smudge_2')} alt="" />
      </BackgroundDiv>
      {lineItems.length ? (
        <>
          <FlexBox className="header" justify="space-between" align="flex-end">
            <HeadingLarge>
              <span className="red">Cart</span>
            </HeadingLarge>
            <div className="shipping-bar">
              <BodyText>
                {trueLineItemsSubtotalNoDiscounts && trueLineItemsSubtotalNoDiscounts >= 250
                  ? 'You have earned free shipping!'
                  : `Spend $${(
                      250 -
                      (trueLineItemsSubtotalNoDiscounts ? trueLineItemsSubtotalNoDiscounts : 0)
                    ).toFixed(2)} more and get free shipping!`}
              </BodyText>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: `${
                      trueLineItemsSubtotalNoDiscounts
                        ? trueLineItemsSubtotalNoDiscounts < 250
                          ? trueLineItemsSubtotalNoDiscounts / 2.5
                          : 100
                        : 0
                    }%`,
                  }}
                  role="progressbar"
                  aria-valuenow={
                    trueLineItemsSubtotalNoDiscounts
                      ? trueLineItemsSubtotalNoDiscounts <= 250
                        ? trueLineItemsSubtotalNoDiscounts
                        : 250
                      : 0
                  }
                  aria-valuemin="0"
                  aria-valuemax="250"
                  aria-busy={false}
                />
              </div>
            </div>
          </FlexBox>
          <div className="table-responsive cartTableWrapper">
            <table className="table cartTable">
              <thead>
                <tr className="line-items-header">
                  <th scope="col" size={3}>
                    <BodyTextAlt md="1rem 0">Product</BodyTextAlt>
                  </th>
                  <th scope="col" size={1}>
                    <BodyTextAlt md="1rem 0">SKU</BodyTextAlt>
                  </th>
                  <th scope="col" size={1}>
                    <BodyTextAlt md="1rem 0">Amount</BodyTextAlt>
                  </th>
                  <th scope="col" size={1}>
                    <BodyTextAlt md="1rem 0" align="right">
                      Total
                    </BodyTextAlt>
                  </th>
                </tr>
              </thead>
              <tbody className="line-items-container">{lineItems}</tbody>
            </table>
          </div>
          <FlexBox justify="space-between" className="cart-bottom">
            <div className="gift-note">
              {!isGift ? (
                <RedButton onClick={enableIsGift} className="gift-btn">
                  <GiftIcon />
                  <span>Send as a gift</span>
                </RedButton>
              ) : (
                <div>
                  <FlexBox style={{ marginBottom: '1rem' }} align="center" className="label">
                    <CheckboxInput
                      onClick={disableIsGift}
                      css={css`
                        display: inline-flex;
                        .checkmarkIcon {
                          display: block;
                          margin-right: 0.5rem;
                          border: 1px solid #5c6566;
                          border-radius: 50%;
                          cursor: pointer;
                          width: 16px;
                          height: 16px;
                          background-size: 100%;
                        }
                        #addGiftNote {
                          width: 100%;
                          height: 100%;
                          opacity: 1;
                          appearance: none;
                          outline: none;
                          border: none;
                        }
                        #addGiftNote:checked + .checkmarkIcon {
                          background-image: url('/images/icons/checkbox.svg');
                          border-color: #d4212c;
                        }
                        #addGiftNote:focus {
                          box-shadow: 0 0 0 1px rgba(212, 33, 44, 0.75),
                            0 0 0 3px rgba(255, 255, 255, 0.75);
                        }
                      `}
                    >
                      <input
                        type="checkbox"
                        name="addGiftNote"
                        id="addGiftNote"
                        value="true"
                        checked={isGift}
                      />
                      <span className="checkmarkIcon" />
                      <span>Add a gift note</span>
                    </CheckboxInput>
                  </FlexBox>
                  <FormControl>
                    <label htmlFor="giftNote">Gift Note</label>
                    <textarea
                      id="giftNote"
                      aria-label="Gift Note"
                      name="giftNote"
                      rows="4"
                      onChange={e => handleGiftNoteChange(e.target.value)}
                      value={giftNote}
                      placeholder="Add your note here"
                      aria-describedby="giftNoteInstruction"
                    />
                    <BodyText id="giftNoteInstruction" style={{ maxWidth: '400px' }}>
                      Free and optional. Don’t worry, we won’t show the pricing details to the
                      person receiving this gift.
                    </BodyText>
                  </FormControl>
                  <WhiteButton onClick={disableIsGift}>Cancel</WhiteButton>
                </div>
              )}
            </div>

            <div className="totals-cont">
              <div className="totals">
                <FlexBox justify="space-between" className="total-item">
                  <BodyTextAlt md="0 1rem .25rem 0" align="right">
                    Items:
                  </BodyTextAlt>
                  <BodyTextAlt md="0 1rem .25rem 0" align="right">
                    ${trueLineItemsBasePriceSubtotalNoDiscounts.toFixed(2)}
                  </BodyTextAlt>
                </FlexBox>
                {trueLineItemsBasePriceSubtotalNoDiscounts > trueLineItemsSubtotalNoDiscounts ? (
                  <FlexBox justify="space-between" className="total-item savings">
                    <BodyTextAlt md="0 1rem 0 0" align="right" color="#d4212c">
                      Savings:
                    </BodyTextAlt>
                    <BodyTextAlt md="0 1rem 0 0" align="right" color="#d4212c">
                      $
                      {(
                        trueLineItemsBasePriceSubtotalNoDiscounts - trueLineItemsSubtotalNoDiscounts
                      ).toFixed(2)}
                    </BodyTextAlt>
                  </FlexBox>
                ) : null}
                {trueLineItemsSubtotalNoDiscounts > trueLineItemsSubtotal ? (
                  <FlexBox justify="space-between" align="center" className="total-item savings">
                    <FlexBox align="center">
                      <TagIcon
                        style={{
                          width: '17px',
                          marginRight: '5px',
                          fill: '#555',
                        }}
                      />
                      <BodyTextAlt md=".25rem 1rem 0 0" align="right" color="#d4212c">
                        {checkout.discountApplications?.[0]?.code}:
                      </BodyTextAlt>
                    </FlexBox>
                    <BodyTextAlt md=".25rem 1rem 0 0" align="right" color="#d4212c">
                      ${(trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal).toFixed(2)}
                    </BodyTextAlt>
                  </FlexBox>
                ) : null}
                {trueLineItemsSubtotalNoDiscounts > trueLineItemsSubtotal ||
                trueLineItemsBasePriceSubtotalNoDiscounts > trueLineItemsSubtotalNoDiscounts ? (
                  <div className="subtotal-divider" />
                ) : null}
                <FlexBox justify="space-between" className="total-item">
                  <BodyTextAlt color="#1d1d1d" md="0 1rem .25rem 0" align="right">
                    Subtotal:
                  </BodyTextAlt>
                  <BodyTextAlt color="#1d1d1d" md="0 1rem 1rem 0" align="right">
                    ${trueLineItemsSubtotal.toFixed(2)}
                  </BodyTextAlt>
                </FlexBox>
              </div>
              <FlexBox align="center">
                <BodyText md="0 1rem 0 0">Taxes and shipping calculated at checkout</BodyText>

                <RedButton as={Link} to="/checkout">
                  {lineItems.length ? 'Checkout' : 'Close'}
                </RedButton>
              </FlexBox>
              <Payments>
                <Image
                  fluid={getImage(cartImages.edges, 'payment_icons')}
                  alt="Supported Payment Methods: Visa, MasterCard, Discover, and American Express"
                />
              </Payments>
            </div>
          </FlexBox>
        </>
      ) : (
        <div className="no-items">
          <HeadingLarge>
            <span className="red">Cart</span>
          </HeadingLarge>
          <p>You have no items in your cart.</p>
          <WhiteButton as={Link} to="/shop">
            Continue Shopping
          </WhiteButton>
        </div>
      )}
    </PageContainerEcomm>
  );
};

export default Cart;
