import React, { useState, useContext, useEffect } from 'react';
import StoreContext from '~/layouts/StoreContext';
import { BodyTextAlt, Heading } from '~/components/.base/headings';
import { FlexBox } from '~/components/.base/containers';
import PlusIcon from '~/images/icons/plus.svg';
import MinusIcon from '~/images/icons/minus.svg';
import { WhiteButton } from '~/components/.base/buttons';
import { UnderscoreLink } from '~/components/.base/links';
import SubscriptionIcon from '~/images/icons/pdp/subscription.svg';
import SubscriptionRemoveIcon from '~/images/icons/pdp/subscription_remove.svg';
import { getProductVariantInventory } from '~/helpers/requests/association-requests';
import { getShopifyImageTransformedSrc } from 'helpers';
import TagIcon from '~/images/icons/tag.svg';

const LineItem = ({ lineItem, index }) => {
  const {
    removeLineItem,
    removeBaseLineItem,
    updateLineItem,
    addVariantToCart,
    closeCart,
    store: { client, checkout, trueLinePrices, lobsterTitle, lobsterSubscriptionTitle },
  } = useContext(StoreContext);

  const [quantityAvailable, setQuantityAvailable] = useState(0);
  const [isUpsellItem, setIsUpsellItem] = useState(false);

  useEffect(() => {
    (async () => {
      if (lineItem.variant.id) {
        const availabilities = await getProductVariantInventory(lineItem.variant.id);
        setQuantityAvailable(availabilities.data.data.node.quantityAvailable);
      }
    })();
  }, []);

  // ******** UP SELL CHECK *********
  useEffect(() => {
    if (lineItem.customAttributes) {
      lineItem.customAttributes.forEach(item =>
        item.key === 'upsell_product' ? setIsUpsellItem(true) : null
      );
    }
  }, []);

  const variantImage = lineItem.variant.image ? (
    <img
      src={getShopifyImageTransformedSrc(lineItem.variant.image.src, '_90x90_crop_center')}
      alt=""
      height="90px"
    />
  ) : null;
  const handleRemove = () => {
    removeLineItem(client, checkout.id, lineItem.id);
  };
  const handleSubtract = () => {
    if (lineItem.quantity === 1) {
      handleRemove();
    } else {
      updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity - 1);
    }
  };
  const handleAdd = () => {
    updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity + 1);
  };
  const handleRegularProductSubstitute = async variantId => {
    await removeLineItem(client, checkout.id, lineItem.id);
    let attrs = [];
    for (let attr of lineItem.customAttributes) {
      if (attr.key !== 'regular_variant_id' && attr.key !== 'subscription_interval') {
        attrs.push({ key: attr.key, value: attr.value });
      }
    }
    // NOTE: remove this until subscriptions are enabled again
    // attrs.push({
    //   key: 'subscription_variant_id',
    //   value: lineItem.variant.id,
    // });
    addVariantToCart(variantId, lineItem.quantity, attrs);
  };
  return trueLinePrices.length && trueLinePrices[index] ? (
    <tr className="line-item">
      <td size={3}>
        <FlexBox align="center" justify="space-between">
          <FlexBox align="center">
            <div className="line-item-img">{variantImage}</div>
            <div>
              <div className="line-item-link">
                <Heading className="line-item-title">
                  {lineItem.title}
                  {lineItem.variant.title !== 'Default Title' ? ` - ${lineItem.variant.title}` : ''}
                </Heading>
              </div>
              <FlexBox align="center">
                <UnderscoreLink as="button" className="remove-btn" onClick={handleRemove}>
                  Remove
                </UnderscoreLink>
                {lineItem.customAttributes.find(prop => prop.key === 'subscription_interval') ? (
                  <BodyTextAlt md="0 0 0 10px">
                    Ships every{' '}
                    {
                      lineItem.customAttributes.find(prop => prop.key === 'subscription_interval')
                        ?.value
                    }{' '}
                    days
                  </BodyTextAlt>
                ) : null}
              </FlexBox>
            </div>
          </FlexBox>
          {lineItem.customAttributes.find(prop => prop.key === 'regular_variant_id') ? (
            <WhiteButton
              h="40px"
              w="40px"
              pd="0px"
              md="0 2rem 10px 0"
              className="subscription-btn no-stroke"
              onClick={() =>
                handleRegularProductSubstitute(
                  lineItem.customAttributes.find(prop => prop.key === 'regular_variant_id').value
                )
              }
              aria-label={`Remove subscription for ${lineItem.title}`}
            >
              <SubscriptionRemoveIcon />
            </WhiteButton>
          ) : null}
        </FlexBox>
      </td>
      <td size={1}>
        <BodyTextAlt>{lineItem.variant.sku}</BodyTextAlt>
      </td>
      <td size={1}>
        {!lineItem.customAttributes.find(prop => prop.key === 'recipient_name') ? (
          <div className="quantity">
            <button
              onClick={() => {
                if (
                  (lobsterTitle === lineItem.title && lineItem.quantity === 2) ||
                  (lobsterSubscriptionTitle === lineItem.title && lineItem.quantity === 2)
                ) {
                  return;
                } else {
                  handleSubtract();
                }
              }}
              aria-label={`Decrease quantity for ${lineItem.title}`}
            >
              <MinusIcon />
            </button>
            {lineItem.quantity < 10 ? `0${lineItem.quantity}` : lineItem.quantity}
            <button
              onClick={handleAdd}
              aria-label={`Increase quantity for ${lineItem.title}`}
              disabled={isUpsellItem || quantityAvailable === lineItem.quantity + 1}
            >
              <PlusIcon style={{ marginBottom: '1px' }} />
            </button>
          </div>
        ) : (
          <div style={{ height: '20px' }} />
        )}
      </td>
      <td size={1}>
        {trueLinePrices[index] &&
        trueLinePrices[index].price !== trueLinePrices[index].basePrice ? (
          trueLinePrices[index].codeDiscountAmount ? (
            <div className="savings">
              <BodyTextAlt md="0 0 5px" align="right">
                <span class="sr-only">Original price:</span>
                <strike>${trueLinePrices[index].basePrice.toFixed(2)}</strike>{' '}
                <span class="sr-only">Current price:</span>${trueLinePrices[index].price.toFixed(2)}
              </BodyTextAlt>
              {Math.round(
                Number(trueLinePrices[index].basePrice) -
                  Number(trueLinePrices[index].price) -
                  Number(trueLinePrices[index].codeDiscountAmount)
              ) > 0 ? (
                <BodyTextAlt md="0 0 5px" align="right" color="#d4212c">
                  Saving $
                  {(
                    Number(trueLinePrices[index].basePrice) -
                    Number(trueLinePrices[index].price) -
                    Number(trueLinePrices[index].codeDiscountAmount)
                  ).toFixed(2)}
                </BodyTextAlt>
              ) : null}
              <FlexBox align="center" justify="flex-end">
                <TagIcon style={{ width: '17px', marginRight: '5px', fill: '#555' }} />
                <BodyTextAlt md="0" align="right" color="#d4212c" style={{ whiteSpace: 'nowrap' }}>
                  {trueLinePrices[index].code}:{` `}$
                  {Number(trueLinePrices[index].codeDiscountAmount).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
            </div>
          ) : (
            <>
              <BodyTextAlt md="0 0 5px" align="right">
                <span class="sr-only">Original price:</span>
                <strike>${trueLinePrices[index].basePrice.toFixed(2)}</strike>{' '}
                <span class="sr-only">Current price:</span>${trueLinePrices[index].price.toFixed(2)}
              </BodyTextAlt>
              <BodyTextAlt md="0" align="right" color="#d4212c">
                Saving $
                {(
                  Number(trueLinePrices[index].basePrice) - Number(trueLinePrices[index].price)
                ).toFixed(2)}
              </BodyTextAlt>
            </>
          )
        ) : (
          <BodyTextAlt align="right">${trueLinePrices[index].price.toFixed(2)}</BodyTextAlt>
        )}
      </td>
    </tr>
  ) : null;
};

export default LineItem;
