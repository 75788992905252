import React from 'react';

const CheckIcon = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" {...props} aria-hidden="true">
            <g fill="none" fillRule="evenodd">
                <g fill={props.noFill ? 'none' : '#D4212C'}>
                    <g>
                        <path
                            d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm3.82 4.616c-.212-.177-.527-.148-.704.064l-4.651 5.578-2.111-2.112-.07-.057c-.195-.135-.464-.116-.638.057-.195.196-.195.512 0 .708l2.5 2.5.074.06c.208.141.497.107.664-.094l5-6 .051-.074c.117-.206.074-.473-.115-.63z"
                            transform="translate(-150 -644) translate(150 644)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CheckIcon;
