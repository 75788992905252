import React, { Fragment, useContext, useEffect } from "react";
import { graphql } from "gatsby";

import StoreContext from "~/layouts/StoreContext";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { Mobile, Default } from "../components/.base/responsive";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { Cart, CartUpsells, CartMobile } from "~/components/CartPage";

const CartPage = ({ data, location }) => {
  const { cartImages } = data;
  const {
    store: { checkout }
  } = useContext(StoreContext);
  return (
    <Fragment>
      <Criteo type="cart" />
      <SEO title="Cart" />
      <DeriveHeaderClasses location={location} />
      <Mobile>
        <CartMobile cartImages={cartImages} />
      </Mobile>
      <Default>
        <Cart cartImages={cartImages} />
        {checkout.lineItems.length ? <CartUpsells /> : null}
      </Default>
    </Fragment>
  );
};

export const query = graphql`
  {
    cartImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/cart/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 80) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default CartPage;
