import axios from 'axios';

const appUrl = process.env.GATSBY_EMBEDDED_API_URL;

const serverUrl = process.env.GATSBY_SERVER_API_URL;

export const getUpsells = async area => {
  const upsells = await axios.get(`${appUrl}/api/upsells?area=${area}`);
  return upsells;
};
export const getUpsellById = (id, area) => {
  return axios.get(
    `${appUrl}/api/upsells-by-id?shopify_id=${id}${area ? `&area=${area}` : ''}`
  );
};
export const getShopifyProduct = handle => {
  const query = `
    query {
      productByHandle(handle: "${handle}") {
        title
        handle
        totalInventory
        variants (first: 1) {
          edges {
            node {
              priceV2 {
                amount
              }
              price {
                amount
              }
              id
            }
          }
        }
        images (first: 1) {
          edges {
            node {
              originalSrc
            }
          }
        }
        media (first: 1) {
          edges {
            node {
              previewImage {
                altText
                originalSrc
              }
            }
          }
        }
      }
    }`;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      },
    }
  );
};
export const submitUpsellPayment = (customer_id, charge_amount, order_id) => {
  return axios.post(`${serverUrl}/api/stripe/upsell-charge`, {
    customer_id,
    charge_amount,
    order_id,
  });
};
