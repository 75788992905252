import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import StoreContext from '~/layouts/StoreContext';
import { getShopifyImageTransformedSrc } from '../../../helpers';
import { getShopifyProduct, getUpsells } from '~/helpers/requests/upsell-requests';
import { Heading, BodyTextAlt } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import { ComponentWrapper, UpsellItem } from './CartUpsells.styled';

const CartUpsells = () => {
  const {
    store: {
      client,
      checkout,
      checkout: { lineItems },
      trueLineItemsSubtotal,
    },
    addVariantToCart,
    removeLineItem,
  } = useContext(StoreContext);
  const [upsellProducts, setUpsellProducts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const upsellRes = await getUpsells('Cart_Page');
        if (upsellRes.data.length) {
          const upsellArr = [];
          for (let item of upsellRes.data) {
            const product = await getShopifyProduct(item.upsell_handle);
            const productData = product?.data?.data;
            if (productData && productData?.productByHandle?.totalInventory > 0) {
              upsellArr.push({
                ...productData.productByHandle,
                discount_amount: item.discount_amount,
                upsell_type: item.upsell_type,
                order_value_threshold: item.order_value_threshold,
                hasAddedToCart: false,
              });
            }
          }
          setUpsellProducts(upsellArr);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleAddToCart = (varId, index) => {
    let props = [];
    props.push({
      key: 'upsell_product',
      value: 'true',
    });
    props.push({
      key: 'upsell_discount',
      value: upsellProducts[index].discount_amount,
    });
    props.push({
      key: 'discount_type',
      value: upsellProducts[index].upsell_type,
    });
    addVariantToCart(varId, 1, props).then(res => {
      setUpsellProducts(prods =>
        prods.map((p, i) => ({
          ...p,
          hasAddedToCart: i === index ? true : p.hasAddedToCart,
        }))
      );
    });
  };
  const handleRemoveFromCart = (varId, index) => {
    const newLineItem = lineItems.find(item => item.variant.id === varId);
    if (newLineItem) {
      const newLineItemID = newLineItem.id;
      removeLineItem(client, checkout.id, newLineItemID).then(res => {
        setUpsellProducts(prods =>
          prods.map((p, i) => ({
            ...p,
            hasAddedToCart: i === index ? false : p.hasAddedToCart,
          }))
        );
      });
    }
  };
  return upsellProducts.length ? (
    <ComponentWrapper className="cart-upsells">
      <Heading>You aren't done there, are you?</Heading>
      <div className={`upsell-cont`}>
        {upsellProducts.map((upsell, i) => {
          if (trueLineItemsSubtotal < upsell.order_value_threshold) {
            return null;
          }
          var price,
            compareAtPrice = Number(upsell.variants.edges[0].node.priceV2.amount);
          if (upsell.upsell_type === 'Percent Discount') {
            price = (compareAtPrice * (100 - Number(upsell.discount_amount))) / 100;
          } else {
            price = compareAtPrice - Number(upsell.discount_amount);
          }
          return (
            <UpsellItem
              className={
                upsellProducts.filter(
                  upsell => trueLineItemsSubtotal > upsell.order_value_threshold
                ).length === 1
                  ? 'only-one'
                  : ''
              }
              key={i}
              align="center"
            >
              <div className="line-item-img">
                {upsell.media.edges.length ? (
                  <img
                    src={getShopifyImageTransformedSrc(
                      upsell.media.edges[0].node.previewImage.originalSrc,
                      '_90x90_crop_center'
                    )}
                    alt=""
                    height="90px"
                  />
                ) : null}
              </div>
              <FlexBox align="center" className="title-price-add">
                <Link to={`/products/${upsell.handle}`} className="cartUpsellItem__link">
                  <Heading as="h3" className="cartUpsellItem__title">
                    {upsell.title}
                  </Heading>
                </Link>
                <div>
                  <span class="sr-only">Current price:</span>
                  <BodyTextAlt
                    style={{
                      marginBottom: '8px',
                    }}
                  >
                    {`$${price.toFixed(2)}`}
                  </BodyTextAlt>
                  <span class="sr-only">Original price:</span>
                  <BodyTextAlt
                    style={{
                      textDecoration: 'line-through',
                    }}
                  >
                    {`$${compareAtPrice.toFixed(2)}`}
                  </BodyTextAlt>
                </div>
                {upsell.hasAddedToCart ? (
                  <WhiteButton
                    className="cartUpsellItem__addRemove"
                    onClick={() => handleRemoveFromCart(upsell.variants.edges[0].node.id, i)}
                  >
                    <span className="reg-text">Added</span>
                    <span className="hover-text">Remove</span>
                  </WhiteButton>
                ) : (
                  <WhiteButton
                    className="cartUpsellItem__addRemove"
                    onClick={() => handleAddToCart(upsell.variants.edges[0].node.id, i)}
                    aria-label="Add Now"
                  >
                    Add Now<span aria-hidden="true">+</span>
                  </WhiteButton>
                )}
              </FlexBox>
            </UpsellItem>
          );
        })}
      </div>
    </ComponentWrapper>
  ) : null;
};

export default CartUpsells;
