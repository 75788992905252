import styled from 'styled-components';
import { breakpoint } from '~/constants/Breakpoint';
import { FlexBox } from '~/components/.base/containers';

export const ComponentWrapper = styled.div`
    width: 90%;
    max-width: 1066px;
    margin: 2rem auto 4rem;
    .upsell-cont {
        display: grid;
        column-gap: 45px;
        grid-template-columns: repeat(auto-fit, minMax(335px, 1fr));
    }
    @media ${breakpoint.maxSm} {
        .upsell-cont {
            grid-template-columns: 1fr;
            /* display: flex;
			flex-direction: column; */
            padding: 0 30px;
        }
    }
`;

export const UpsellItem = styled(FlexBox)`
    background-color: #d4212c;
    border-radius: 60px;
    /* width: 375px; */
    /* height: 120px; */
    max-width: 100%;
    margin: 40px 45px 40px 30px;
    padding: 35px 0 45px;
    position: relative;
    &.only-one {
        max-width: 50%;
    }
    .line-item-img {
        flex: 0 0 90px;
        max-width: 90px;
        height: 90px;
        border-radius: 10px;
        border: solid 1px #e1e4e4;
        background-color: #fff;
        position: relative;
        left: -30px;
        img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
        }
    }
    .cartUpsellItem__link {
        outline: none;
        &:focus {
            &:not(:disabled) {
                box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
            }
        }
    }
    .cartUpsellItem__title {
        /* max-width: 150px; */
        color: #fff;
        margin: 0;
    }
    .cartUpsellItem__addRemove {
        &:focus {
            &:not(:disabled) {
                box-shadow: 0 0 0 3px rgba(46, 50, 51, 0.6);
            }
        }
    }
    p {
        margin: 0 -35px 0 1rem;
        color: #fff;
    }
    button {
        position: relative;
        right: -55px;
        flex: 0 0 140px;
        max-width: 140px;
        padding: 10px 20px;
        .hover-text {
            display: none;
        }
        @media ${breakpoint.xl} {
            &:hover {
                border-color: #fff !important;
                .hover-text {
                    display: block;
                }
                .reg-text {
                    display: none;
                }
            }
        }
    }
    @media ${breakpoint.maxLg} {
        /* width: calc(100% - 30px); */
        /* height: fit-content; */
        padding: 15px 0 33px;
        margin: 20px 0 45px 15px;
        .title-price-add {
            flex-direction: column;
            align-items: flex-start;
        }
        h2 {
            margin-top: 20px;
        }
        p {
            margin: 1rem 0;
        }
        button {
            position: absolute;
            left: 90px;
            right: auto;
            bottom: 0;
            transform: translateY(50%);
        }
    }
    @media ${breakpoint.maxMd} {
        &.only-one {
            max-width: 100%;
        }
    }
`;
